import { useMode } from "ModeContext";
import PriceChart from "components/Chart";
import { useNexusTokenPrice } from "hooks/useNexusTokenPrice";
import { useEffect, useState } from "react";
import CryptoDashboard from "./CryptoDashboard";

const ProvideAccess = () => {
  const { mode } = useMode();

  const nexusTokenData = useNexusTokenPrice();
  const [euusRate, setEuusRate] = useState(
    nexusTokenData?.isFetched ? nexusTokenData?.data?.data.data.EUUS : null
  );

  const [useuRate, setUseuRate] = useState(
    nexusTokenData?.isFetched ? nexusTokenData?.data?.data.data.USEU : null
  );

  useEffect(() => {
    if (!nexusTokenData?.isLoading && nexusTokenData !== null) {
      setEuusRate(nexusTokenData?.data?.data.data.EUUS);
      setUseuRate(nexusTokenData?.data?.data.data.USEU);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nexusTokenData?.isLoading, nexusTokenData?.data, nexusTokenData]);
  return (
    <div
      style={{
        backgroundImage: `url('/assets/images/lg-chart.png')`,
        backgroundColor: "#000000",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        // className={`w-max-[1200] flex flex-col relative items-center sm:px-14 px-5 bg-white transition-all gap-16 py-36`}
        className={`relative z-20 flex flex-col items-center justify-between w-full sm:flex-cols-2 max-w-[1200px] m-auto lg:flex-cols-4 gap-6`}
      >
        <div
          className={`flex flex-col items-center justify-center gap-3 transition-all text-white mt-16 px-8`}
        >
          <div
            className={`relative flex flex-col text-4xl font-bold w-fit text-inherit`}
          >
            <span className="text-center sm:text-4xl text-3xl">
              Realtime prices / Exchange rates

            </span>          </div>
          <div
            className={`relative flex flex-col uppercase text-lg w-fit text-inherit`}
          >
            <span className=" text-inherit">Trade / Buy</span>
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-center px-5 w-full gap-5 md:gap-3">
          <div className="lg:w-[48%] w-full lg:mr-3">
            <PriceChart
              title="EUUS"
              from="EUR"
              rate={euusRate}
              to="USD"
              loadingRate={!nexusTokenData?.isFetched}
              image_dark={
                <img
                  loading="lazy"
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "/assets/images/Nexus Pro_Icons_03-min.png"
                  }
                  alt="wing"
                  className="h-12 w-12"
                />
              }
            />
          </div>
          <div className="lg:w-[48%] w-full lg:ml-3">
            <PriceChart
              title="USEU"
              from="USD"
              to="EUR"
              rate={useuRate}
              euusRate
              loadingRate={!nexusTokenData?.isFetched}
              image_dark={
                <img
                  loading="lazy"
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "/assets/images/Nexus Pro_Icons_04-min.png"
                  }
                  alt="wing"
                  className="h-12 w-12"
                />
              }
            />
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-between w-full">
          <CryptoDashboard />
        </div>
      </div>
    </div>
  );
};

export default ProvideAccess;
