import { Carousel } from "react-responsive-carousel";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const tokens = [
  {
    name: "bitstorage",
    image: "/assets/images/lg-bitstorage.png",
    useuLink:
      "https://bitstorage.finance/spot/trading/USEUUSDT?interface=classic",
    euusLink:
      "https://bitstorage.finance/spot/trading/EUUSUSDT?interface=classic",
  },
  {
    name: "DEX-TRADE",
    image: "/assets/images/lg-dex-trade.png",
    useuLink: "https://dex-trade.com/spot/trading/USEUUSDT?interface=classic",
    euusLink: "https://dex-trade.com/spot/trading/EUUSUSDT?interface=classic",
  },
  {
    name: "FINEXBOX",
    image: "/assets/images/lg-finexbox.png",
    useuLink: "https://www.finexbox.com/market/pair/useu-btc.html",
    euusLink: "https://www.finexbox.com/market/pair/euus-btc.html",
  },
  {
    name: "LATOKEN",
    image: "/assets/images/lg-latoken.png",
    useuLink: "https://latoken.com/exchange/USEU_USDT",
    euusLink: "https://latoken.com/exchange/EUUS_USDT",
  },
  {
    name: "Azbit",
    image: "/assets/images/lg-azbit.png",
    useuLink: "https://azbit.com/exchange/USEU_USDT",
    euusLink: "https://azbit.com/exchange/EUUS_USDT",
  },
  {
    name: "P2B",
    image: "/assets/images/lg-p2b.png",
    useuLink: "https://p2pb2b.com/trade/USEU_USDT/",
    euusLink: "https://p2pb2b.com/trade/EUUS_USDT/",
  },
];

const wallets = [
  {
    name: "coinbase",
    image: "/assets/images/lg-coinbase.png",
    euusLink: "https://www.coinbase.com/converter/euus/usd",
    useuLink: "https://www.coinbase.com/en-tr/converter/useu/usd",
  },
  {
    name: "bybit",
    image: "/assets/images/lg-bybit.png",
    euusLink: "https://www.bybit.com/en/coin-price/nexus-pro-euus/",
    useuLink: "https://www.bybit.com/coin-price/nexus-pro-useu/",
  },
  {
    name: "okx",
    image: "/assets/images/lg-okx.png",
    euusLink:
      "https://www.okx.com/web3/detail/1/0x735acdedd91a80334ff72f07bff41e1eecf26677",
    useuLink:
      "https://www.okx.com/web3/detail/1/0xe07ecc676daf0b24b24a1c46c966d9c463984b38",
  },
  {
    name: "gateio",
    image: "/assets/images/lg-gateio.png",
    euusLink: "https://www.gate.io/price/nexus-pro-euus-euus",
    useuLink: "https://www.gate.io/price/nexus-pro-useu-useu",
  },
  {
    name: "mexc",
    image: "/assets/images/lg-mexc.png",
    euusLink: "https://www.mexc.com/price/nexus-pro-euus",
    useuLink: "https://www.mexc.com/price/nexus-pro-useu",
  },
  {
    name: "3commas",
    image: "/assets/images/lg-3commas.png",
    euusLink: "https://3commas.io",
    useuLink: "https://3commas.io",
  },
  {
    name: "be-in-crypto",
    image: "/assets/images/lg-be-in-crypto.png",
    euusLink: "https://beincrypto.com/convert/nexus-pro-euus-to-usd/",
    useuLink: "https://beincrypto.com/convert/nexus-pro-useu-to-usd/",
  },
  {
    name: "fxempire",
    image: "/assets/images/lg-fxempire.png",
    euusLink: "https://www.fxempire.com/crypto/nexus-pro-euus",
    useuLink: "https://www.fxempire.com/crypto/nexus-pro-useu",
  },
  {
    name: "mc2",
    image: "/assets/images/lg-mc2.png",
    euusLink: "https://app.mc2.fi/tokens/EUUS-006bd1",
    useuLink: "https://app.mc2.fi/tokens/USEU-6a34b5",
  },
  {
    name: "messari",
    image: "/assets/images/lg-messari.png",
    euusLink: "https://messari.io/project/nexus-pro-euus",
    useuLink: "https://messari.io/project/nexus-pro-useu",
  },
  {
    name: "coinstats",
    image: "/assets/images/lg-coinstats.png",
    euusLink: "https://coinstats.app/coins/nexus-pro-euus/",
    useuLink: "https://coinstats.app/coins/nexus-pro-useu/",
  },
  {
    name: "live-coin-watch",
    image: "/assets/images/lg-live-coin-watch.png",
    euusLink: "https://www.livecoinwatch.com/price/NexusProEUUS-EUUS",
    useuLink: "https://www.livecoinwatch.com/price/NexusProEUUS-USEU",
  },
  {
    name: "forbes",
    image: "/assets/images/lg-forbes.png",
    euusLink:
      "https://www.forbes.com/digital-assets/assets/nexus-pro-euus-euus/",
    useuLink:
      "https://www.forbes.com/digital-assets/assets/nexus-pro-useu-useu/",
  },
  {
    name: "dappradar",
    image: "/assets/images/lg-dappradar.png",
    euusLink: "https://dappradar.com/token/nexus-pro-euus",
    useuLink: "https://dappradar.com/token/nexus-pro-useu",
  },
  {
    name: "coinmarketcal",
    image: "/assets/images/lg-coinmarketcal.png",
    euusLink: "https://coinmarketcal.com/en/coin/nexus-pro-euus",
    useuLink: "https://coinmarketcal.com/en/coin/nexus-pro-useu",
  },
  {
    name: "coinranking",
    image: "/assets/images/lg-coinranking.png",
    euusLink: "https://coinranking.com/coin/bKHK5VSpY+euus-euus",
    useuLink: "https://coinranking.com/coin/ZHo2SUKjq+useu-useu",
  },
  {
    name: "dexscreener",
    image: "/assets/images/lg-dexscreener.png",
    euusLink:
      "https://dexscreener.com/ethereum/0x3bCa4BA8731655cc6F7Be6387955f5a2Edae6656",
    useuLink:
      "https://dexscreener.com/ethereum/0xE3704bB68f505eE8A1A664d0776029c4e4E6c2eF",
  },
];

const Display_token = ({ token, index }) => {
  return (
    <div
      key={index}
      className="flex justify-center flex-col bg-white border border-gray-300 rounded-[34px] shadow-lg px-5 py-3 my-2 w-full sm:w-[381px] text-center h-[197px]"
    >
      {/* token logo */}
      <div className="flex items-center justify-center mb-6">
        <div className="flex justify-center w-full">
          <img
            src={token.image}
            alt={`${token.name} logo`}
            className="h-28 object-contain"
          ></img>
        </div>
      </div>
      {/* buy sell button */}
      <div className="flex items-start justify-center gap-4">
        <div
          className=" inline-flex cursor-pointer items-center bg-gradient-to-r from-[#C0EAFF] to-[#DFDFDF] text-gray-700 py-2 rounded-full shadow-md transition duration-200 w-[48%]"
          style={{ height: "2.5rem" }}
          href={token.euusLink}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="/assets/images/Nexus Pro_Icons_03-min.png"
            alt="Trade EUUS Now"
            className="w-10 h-10 object-contain"
            style={{ width: "2.5rem" }}
          />
          <div className="justify-center w-full">
            <Link to={token.euusLink}>
              <span className="text-xs leading-tight w-full mr-2 ml-2 text-nowrap">
                Trade <span className="font-bold">EUUS</span> Now
              </span>
            </Link>
          </div>
        </div>

        <div
          className="inline-flex cursor-pointer items-center bg-gradient-to-r from-[#C0EAFF] to-[#DFDFDF] text-gray-700 py-2 rounded-full shadow-md transition duration-200  w-[48%] "
          style={{ height: "2.5rem" }}
          href={token.useuLink}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="/assets/images/Nexus Pro_Icons_04-min.png"
            alt="Trade USED Now"
            className="w-10 h-10 object-contain"
            style={{ width: "2.5rem" }}
          />
          <div className="justify-center w-full">
            <Link to={token.useuLink}>
              <span className="text-xs leading-tight w-full mr-2 ml-2 text-nowrap">
                Trade <span className="font-bold">USEU</span> Now
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const Sub_disp = (data, Show) => {
  let disp = [];
  for (let i = 0; i < data.length / 3; i++) {
    let k = i * 3;
    disp[i] = (
      <div key={i} className="w-full pb-8 flex flex-col justify-center">
        {k < data.length ? <Show token={data[k]} /> : null}
        {k + 1 < data.length ? <Show token={data[k + 1]} /> : null}
        {k + 2 < data.length ? <Show token={data[k + 2]} /> : null}
      </div>
    );
  }
  return disp;
};

const TradingPlatforms = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const handleResize = () => {
    if (window.innerWidth < 430) setIsMobileMenuOpen(false);
    else setIsMobileMenuOpen(true);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    handleResize();
  }, []);
  return (
    <div
      className={`w-full relative h-full flex flex-col overflow-hidden items-center py-16 bg-gradient-to-t from-[#999999] to-[#ffffff] px-4 sm:px-0 bg-white text-white transition-all`}
    >
      {/* <div
        className="absolute w-[1000px] h-[1000px] rounded-full -top-[500px] -left-[500px] blur-3xl"
        style={{
          background:
            "radial-gradient(circle, rgba(0,136,205,0.5) 1%, rgba(87,87,168,0) 100%)",
        }}
      /> */}
      <div
        className={`absolute w-[1000px] h-[1000px] rounded-full blur-xl -bottom-[300px] -right-[300px]`}
        style={{
          background:
            "radial-gradient(circle, rgba(0,136,205,0.2) 1%, rgba(87,87,168,0) 100%)",
        }}
      />
      {/* Exchange List */}
      <div className="relative z-20 flex flex-col items-center w-full max-w-[1200px] m-auto gap-6">
        <div className="flex-col items-center justify-center w-full text-black">
          <div className="flex flex-col items-center justify-center mb-4 text-3xl font-bold text-center px-4">
            <p>TOKEN LISTED IN FOLLOWING EXCHANGES</p>
          </div>
        </div>
        <div className="px-5">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 items-center pb-8 gap-4">
            {isMobileMenuOpen ? (
              tokens.map((token, index) => (
                <Display_token token={token} index={index} />
              ))
            ) : (
              <div className="flex flex-col justify-center w-full">
                <Carousel
                  showStatus={false}
                  useKeyboardArrows
                  className="Exchange_carousel"
                  width={"100%"}
                  showArrows={true}
                  centerSlidePercentage={100}
                  showThumbs={false}
                >
                  {Sub_disp(tokens, Display_token).map((item) => item)}
                </Carousel>
              </div>
            )}
          </div>
          <div className="w-full mt-12 border-b-2 border-black border-dashed"></div>
        </div>
      </div>
      {/* Trade Tokens */}
      <div className="relative z-20 flex flex-col items-center w-full max-w-[1200px] m-auto gap-6">
        <div className="flex-col items-center justify-center w-full text-black mt-6">
          <div className="flex flex-col items-center justify-center my-4 md:my-16 text-3xl font-bold text-center px-4">
            <p>Trade Tokens on Web3.0/Wallets</p>
          </div>
        </div>
        {/* <div className="flex flex-col w-full md:p-12 md:flex-wrap md:flex-row items-center md:justify-between pb-8 gap-4"> */}
        <div className="px-5">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 items-center gap-4">
            {isMobileMenuOpen ? (
              wallets.map((token, index) => (
                <Display_token token={token} index={index} />
              ))
            ) : (
              <div className="flex flex-col justify-center w-full">
                <Carousel
                  showStatus={false}
                  showArrows={true}
                  useKeyboardArrows={true}
                  className="Trade_carousel"
                  width={"100%"}
                  // centerMode={true}
                  centerSlidePercentage={100}
                  showThumbs={false}
                >
                  {Sub_disp(wallets, Display_token).map((item) => item)}
                </Carousel>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradingPlatforms;
