import React from "react";
import {
  fixDigitFilter,
  formatCurrencyWithDecimalString,
} from "utils/formatNumber";

const PriceBox = ({
  token = "",
  imgUrl = "",
  priceOtherPlatform = "",
  price = "",
  openModal,
  onClickTrade,
  blur = false,
}) => {
  const plusPercentage = fixDigitFilter(
    price > priceOtherPlatform
      ? ((price - priceOtherPlatform) * 100) / price
      : 0
  );

  return (
    <div
      className={`${
        blur ? "opacity-50" : ""
      } border border-gray-300 selected-bigger_md p-6 py-4 pb-2 rounded-[15px] selected-bigger-md bg-white shadow-md  w-[220px] h-[200px] m-2 my-10 lg:m-12 xl:mx-0 xl:w-auto xl:h-auto m-auto`}
    >
      <h2 className="text-lg font-medium">
        <div className="flex items-center justify-center mb-2">
          <img
            src={imgUrl}
            alt={`${imgUrl} logo`}
            className="w-12 h-auto"
            style={{ width: "20px" }}
          />
          <h2 className="pl-2 text-lg font-medium">{token} Price:</h2>
        </div>
        <span
          className="text-gray-900 cursor-pointer underline"
          onClick={() => openModal(token)}
        >
          {formatCurrencyWithDecimalString(priceOtherPlatform)}
        </span>
      </h2>
      <span className="text-sm text-gray-600">Get +{plusPercentage}%</span>
      <div className=" text-xm font-semibold">
        <p> Sell Your {token} For </p>
        <span className="text-blue-600 ">
          {formatCurrencyWithDecimalString(price)}
        </span>
      </div>
      <button
        className="mt-2 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        onClick={onClickTrade}
      >
        Trade Now
      </button>
    </div>
  );
};

export default PriceBox;
