import React, { useEffect, useState } from "react";
import PriceBox from "./PriceBox";
import ExchangeTableModal from "./ExchangeTableModal";
import { usePriceOtherPlatform } from "hooks/usePriceOtherPlatform";
import { useNexusTokenPrice } from "hooks/useNexusTokenPrice";
import { Carousel } from "react-responsive-carousel";

const assets = ["BTC", "ETH", "USDT", "USDC", "WBTC", "WETH"];
const assetsUrl = {
  BTC: "https://assets.coingecko.com/coins/images/1/standard/bitcoin.png?1696501400",
  ETH: "https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628",
  USDT: "https://assets.coingecko.com/coins/images/325/standard/Tether.png?1696501661",
  USDC: "https://assets.coingecko.com/coins/images/6319/standard/usdc.png?1696506694",
  WBTC: "https://assets.coingecko.com/coins/images/7598/standard/wrapped_bitcoin_wbtc.png?1696507857",
  WETH: "https://assets.coingecko.com/coins/images/2518/standard/weth.png?1696503332",
};
const CryptoDashboard = () => {
  const priceOtherPlatform = usePriceOtherPlatform();
  const tokenPrice = useNexusTokenPrice();

  const [priceOtherPlatformData, setPriceOtherPlatformData] = useState(null);
  const [tokenPriceData, setTokenPriceData] = useState(null);
  const [selectedItem, setSelectedItem] = useState(1);
  const handleOnChange = (index) => {
    setSelectedItem(index); // Update the selected index when the slide changes
  };
  const [selectedSymbol, setSelectedSymbol] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    if (priceOtherPlatform !== null && !priceOtherPlatform?.isLoading) {
      setPriceOtherPlatformData(priceOtherPlatform?.data?.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceOtherPlatform?.isLoading]);

  useEffect(() => {
    if (tokenPrice !== null && !tokenPrice?.isLoading) {
      setTokenPriceData(tokenPrice?.data?.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenPrice?.isLoading]);

  const openModal = (symbol) => {
    setSelectedSymbol(symbol);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onClickTrade = () => {
    window.open(`${process.env.REACT_APP_DASHBOARD_URL}`, "_blank");
  };

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const handleResize = () => {
    if (window.innerWidth <= 760) setIsMobileMenuOpen(false);
    else setIsMobileMenuOpen(true);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    handleResize();
  }, []);

  const Crypto_list = assets.map((token, index) => {
    return (
      <PriceBox
        key={token}
        imgUrl={assetsUrl[token]}
        token={token}
        blur={isMobileMenuOpen ? false : index !== selectedItem}
        priceOtherPlatform={
          priceOtherPlatformData && !priceOtherPlatformData.isLoading
            ? priceOtherPlatformData[token].average
            : ""
        }
        price={
          tokenPriceData && !tokenPriceData.isLoading
            ? tokenPriceData[token]
            : ""
        }
        openModal={openModal}
        onClickTrade={onClickTrade}
      />
    );
  });

  return (
    <div className="w-full text-center max-w-[1200px] m-auto">
      <h1 className="text-2xl text-white  font-semibold m-6 hidden lg:block ">
        Price Up on Nexus Pro Dashboard{" "}
      </h1>
      {/* <div className="relative felx flex-col items-center md:justify-between lg:flex lg:flex-row justify-center items-center  my-16  md:gap-y-10"> */}
      <div className="flex flex-row flex-wrap w-full justify-around my-10 px-0">
        {isMobileMenuOpen ? (
          Crypto_list
        ) : (
          <div className=" w-full items-center  ">
            <Carousel
              useKeyboardArrows
              showArrows={true}
              className="home_carousel"
              width={"100vw"}
              autoFocus={true}
              onChange={handleOnChange}
              selectedItem={selectedItem}
              centerMode={true}
              centerSlidePercentage={110 - window.innerWidth / 11}
              // centerSlidePercentage={window.innerWidth <= 600 ? 80: 40}
            >
              {Crypto_list}
            </Carousel>
          </div>
        )}
        {}
      </div>
      {isModalOpen && (
        <ExchangeTableModal
          token={selectedSymbol}
          priceOtherPlatform={
            priceOtherPlatformData && !priceOtherPlatformData.isLoading
              ? priceOtherPlatformData[selectedSymbol]
              : null
          }
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default CryptoDashboard;
