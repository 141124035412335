import classNames from "classnames";

const ButtonDark = ({
  dark,
  size,
  label,
  color,
  onClickHandle,
  pb,
  pt,
  w,
  padding,
}) => {
  return (
    <button
      type="button"
      onClick={onClickHandle}
      className={classNames(
        `inline-block rounded-xl ${
          size ? size : ""
        } ${padding} font-inter py-5 ${pb} ${pt} ${w} text leading-normal transition duration-150 ease-in-out bg-[#0088CD] dark:text-gray text-white hover:shadow-[6px_5px_13px_0px_#0e6a94]`
      )}
    >
      {label}
    </button>
  );
};

export default ButtonDark;
