import Button from "components/Input/Button_dark";
import { useEffect, useState } from "react";

const Transparency = ({ setSelectedPage }) => {
  const [cardWidth, setCardWidth] = useState((382 / window.innerWidth) * 100);
  const [mobileCardWidth, setMobileCardWidth] = useState("w-[350px]");

  const handleResize = () => {
    setCardWidth((350 / window.innerWidth) * 100);

    if (window.innerWidth > 440 && window.innerWidth <= 1024) {
      setMobileCardWidth("w-full");
    } else if (window.innerWidth <= 440) {
      if (window.innerWidth > 370) {
        setMobileCardWidth("w-[350px]");
      } else {
        setMobileCardWidth("w-full");
      }
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    handleResize();
  }, []);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const backgroundImage = `url("${process.env.REACT_APP_PUBLIC_URL}/assets/images/Transparency_bg-min.png")`;
  return (
    <div className="relative flex items-center justify-center">
      <div className="relative z-10 flex flex-col items-center px-6 sm:px-4 py-12 max-w-[1200px] w-full">
        <div className="flex justify-start sm:justify-center sm:px-32 mb-8 lg:mb-16 p-2">
          <h1
            className={`font-bold lg:text-4xl md:text-3xl text-3xl text-white text-left sm:text-center`}
          >
            Building Trust Through Transparency: Nexus Pro's Commitment to
            Stability and Reserve Reports
          </h1>
        </div>
        <div className="flex lg:flex-row flex-col items-center p-2">
          <div className="flex flex-col justify-start md:px-24 lg:pl-0 gap-3 text-white items-start md:text-center lg:text-left lg:items-start lg:mb-0 md:mb-24 mb-12">
            <p className="text-xl font-inter mb-6">
              At Nexus Pro, transparency and stability are at the core of
              everything we do. We believe in providing our users with full
              visibility into our operations and financial health, ensuring
              trust and confidence in our platform.
            </p>
            <p className="text-xl font-inter mb-6">
              Our commitment to transparency is demonstrated through regular
              reserve reports, which provide detailed insights into the assets
              backing our tokens. With Nexus Pro, you can rest assured knowing
              that your investments are supported by a secure and stable
              foundation.
            </p>
            <div className="flex items-center justify-start sm:justify-center lg:justify-start w-full">
              <Button
                dark
                padding={"xl:px-11 px-5 xl:py-4"}
                label="View Reserves"
                onClickHandle={() => {
                  scrollToTop();
                  setSelectedPage("transparency");
                }}
              />
            </div>
          </div>

          <div
            className={`flex flex-col w-full md:flex-row md:px-20 lg:px-0 lg:flex-col items-center gap-10 text-white`}
          >
            {[
              { mark: "€", img: "/assets/images/Nexus Pro_Icons_03-min.png" },
              { mark: "$", img: "/assets/images/Nexus Pro_Icons_04-min.png" },
            ].map((val, index) => (
              <div
                key={index}
                className={`bg-white rounded-2xl  transition-all shadow-[1px_1px_1px_1px_rgba(0,0,0,0.1)] flex flex-col p-4 sm:p-5 w-full sm:w-[350px]`}
              >
                <div
                  className={`flex flex-row items-center justify-center gap-6 transition-all`}
                >
                  <div className="flex flex-col items-center gap-3 w-24">
                    <img
                      loading="lazy"
                      src={process.env.REACT_APP_PUBLIC_URL + val.img}
                      alt="wing"
                    />
                  </div>
                  <div className="flex flex-col text-start items-center">
                    <div className="font-bold text-gray-700">
                      <p className="text-xl text-nowrap text-blue-gray-800 sm:text-start">
                        NexusPro in Circulation
                      </p>
                      <p className="mt-1 text-sm font-inter text-start">
                        as of February 15, 2024
                      </p>
                    </div>
                    <p className="w-full text-3xl font-bold text-blue-500 text-start">
                      {val.mark}5B
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="absolute top-0 z-0 w-full h-full shadow">
        <div
          className="absolute w-full h-full "
          style={{
            backgroundImage,
            backgroundPosition: "right",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "inherit",
            backgroundSize: "cover",
          }}
        />
      </div>
    </div>
  );
};

export default Transparency;
